import Link from 'next/link';
import { Col, Row } from 'react-bootstrap';
import styles from './pageNavigation.module.scss';
import { useRouter } from 'next/router';

export type PageNavigationUrl = {
    url: string;
    label: string;
};

type PageNavigationProps = {
    navUrls: PageNavigationUrl[];
};

const PageNavigation = ({ navUrls }: PageNavigationProps) => {
    const router = useRouter();

    return (
        <Row className={styles.wrapper}>
            {navUrls.map((navElement) => (
                <Col sm={4} key={navElement.url}>
                    <Link href={navElement.url}>
                        <a className={router.pathname === navElement.url ? styles.active : ''}>{navElement.label}</a>
                    </Link>
                </Col>
            ))}
        </Row>
    );
};

export default PageNavigation;
