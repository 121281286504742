import MessageBox from 'components/_modal/MessageBox';
import { useCallback, useState, useEffect } from 'react';
import RequestAccessFormModal from './FormModal';
import RequestSent from './RequestSent';

export type RequestAccessProps = {
    toggle: () => void;
    isShowing: boolean;
};

const RequestAccess = ({ toggle, isShowing }: RequestAccessProps) => {
    const [isComplete, setIsComplete] = useState(false);

    const onComplete = useCallback(() => {
        setIsComplete(true);
    }, []);

    useEffect(() => {
        // we need to reset it on toggle
        setIsComplete(false);
    }, [toggle]);

    return (
        <>
            {isComplete ? (
                <MessageBox title="Request submitted!" isShowing={isShowing} toggle={toggle} onComplete={onComplete}>
                    <RequestSent />
                </MessageBox>
            ) : (
                <RequestAccessFormModal toggle={toggle} isShowing={isShowing} onComplete={onComplete} />
            )}
        </>
    );
};

export default RequestAccess;
