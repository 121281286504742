import LoginPage from 'components/LoginPage';
import useUserSession from 'hooks/useUserSession';
import { Container } from 'react-bootstrap';
import styles from '../pageWrapper.module.scss';
import GeneralSearchPanel from '../../_form/GeneralSearchPanel';
import PageNavigation from 'components/_navigation/PageNavigation';
import { ReactNode } from 'react';

const navUrls = [
    { url: '/', label: 'Official questionnaires' },
    { url: '/myCustom', label: 'Custom questionnaires' },
    { url: '/tree-view', label: 'Item classification' },
];

type HomePageWrapperProps = {
    children: ReactNode;
};

const HomePageWrapper = ({ children }: HomePageWrapperProps) => {
    const { isAuthenticated } = useUserSession();

    if (!isAuthenticated) {
        return <LoginPage />;
    }
    return (
        <>
            <GeneralSearchPanel />
            <PageNavigation navUrls={navUrls} />
            <div className={styles.indexBg}>
                <Container className={styles.wrapper}>{children}</Container>
            </div>
        </>
    );
};

export default HomePageWrapper;
