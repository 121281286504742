import recaptchaKey from 'consts/recaptchaKey';
import ReCAPTCHA from 'react-google-recaptcha';
import { Row, Col } from 'react-bootstrap';
import { useController, UseControllerProps } from 'react-hook-form';
import styles from './recaptcha.module.scss';

interface ReCaptchaProps<TValues> extends UseControllerProps<TValues> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue: (name: any, value: any, config: any) => void;
}

function ReCaptcha<TValues>(props: ReCaptchaProps<TValues>) {
    const {
        field: { name },
        fieldState: { error },
    } = useController(props);

    const { setValue } = props;

    function onRecaptchaChange(token: string | null) {
        setValue(name, token || undefined, {
            // only validate when the user clicks on it,
            // and not when a challenge expires (token is null in this case)
            // recaptcha managing his own expiration message
            shouldValidate: !!token,
        });
    }

    return (
        <Row className="m-5">
            <Col />
            <Col sm="8">
                <ReCAPTCHA
                    sitekey={recaptchaKey}
                    /* eslint-disable-next-line */
                    onChange={onRecaptchaChange}
                />
                <p className={error ? styles.errorMessage : 'd-none'}>{error?.message}</p>
            </Col>
        </Row>
    );
}

export default ReCaptcha;
