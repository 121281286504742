/* eslint-disable @typescript-eslint/no-unsafe-assignment,react/jsx-props-no-spreading */
import Field from 'components/_form/Field';
import { Form } from 'react-bootstrap';
import RegisterRequest from '_types/api/RegisterRequest';
import FormModal from 'components/_modal/FormModal';
import ReCaptcha from 'components/_form/ReCaptcha';
import ProfileSelect from 'components/_form/Select/ProfileSelect';
import useApi from 'hooks/useApi';
import requestAccessPath from 'consts/paths/api/auth/requestAccess';
import CountrySelect from 'components/_form/Select/CountrySelect';
import profileStyles from 'components/_form/Select/ProfileSelect/profileselect.module.scss';
import styles from './_requestAccess.module.scss';
import { requestAccessSchema, requestAccessDefaultValues } from './schema';

export type RequestAccessProps = {
    toggle: () => void;
    isShowing: boolean;
    onComplete: (value: RegisterRequest) => void;
};

const RequestAccessFormModal = ({ isShowing, toggle, onComplete }: RequestAccessProps) => {
    const api = useApi();

    const sendRequestAccess = async (data: RegisterRequest) => api.post<RegisterRequest>(requestAccessPath, data);

    return (
        <FormModal
            title="Request access"
            toggle={toggle}
            isShowing={isShowing}
            validationSchema={requestAccessSchema}
            submitButtonText="Request access"
            initialValues={requestAccessDefaultValues}
            onSubmit={sendRequestAccess}
            onComplete={onComplete}
            customSize
            isResetAfterSubmit={true}
        >
            {({ register, setValue, control, formState: { errors }, watch }) => (
                <>
                    <p className={styles.formDescription}>
                        After filling in the form, you will be contacted by an administrator.
                    </p>
                    <p className={styles.formDescription}>
                        Your account can be activated only by an administrator. Please make sure your e-mail address is
                        correct.
                    </p>
                    <hr className={styles.horizontal} />
                    <Field label="profile" id="profile" feedback={errors.profile}>
                        <ProfileSelect
                            control={control}
                            name="profile"
                            label="Choose the profile that best describes you..."
                        />
                        {!watch('profile') && (
                            <p className={`${profileStyles.profileDescription} mt-3`}>
                                You are a member of the EORTC Quality of Life Group and need access to the Item Library
                                for managing requests.
                            </p>
                        )}
                    </Field>
                    <Field label="name" id="name" feedback={errors.name}>
                        <Form.Control
                            isInvalid={!!errors.name}
                            placeholder="Your full name, e. g. John Doe"
                            {...register('name')}
                        />
                    </Field>
                    <Field label="email" id="email" feedback={errors.email}>
                        <Form.Control
                            type="email"
                            {...register('email')}
                            placeholder="Your email addres (will also be your login)"
                            isInvalid={!!errors.email}
                        />
                    </Field>
                    <Field label="password" id="password" feedback={errors.password}>
                        <Form.Control
                            type="password"
                            {...register('password')}
                            placeholder="chose a password.."
                            isInvalid={!!errors.password}
                        />
                    </Field>
                    <Field label="confirm password" id="confirmPassword" feedback={errors.confirmPassword}>
                        <Form.Control
                            type="password"
                            {...register('confirmPassword')}
                            isInvalid={!!errors.confirmPassword}
                        />
                    </Field>
                    <Field label="country" id="country" feedback={errors.country}>
                        <CountrySelect control={control} name="country" label="Your country of residence" />
                    </Field>
                    <Field label="organisation" id="organisation" feedback={errors.organisation}>
                        <Form.Control
                            placeholder="The company or institution you work for"
                            {...register('organisation')}
                            isInvalid={!!errors.organisation}
                        />
                    </Field>
                    <Field label="motivation" id="motivation" feedback={errors.motivation}>
                        <Form.Control
                            as="textarea"
                            placeholder="Why you need to access the item library"
                            {...register('motivation')}
                            isInvalid={!!errors.motivation}
                        />
                    </Field>
                    <hr className={styles.horizontal} />
                    <ReCaptcha name="recaptchaResponse" setValue={setValue} control={control} />
                </>
            )}
        </FormModal>
    );
};

export default RequestAccessFormModal;
