/* eslint-disable @typescript-eslint/no-unsafe-assignment,react/jsx-props-no-spreading */
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import FormModal from 'components/_modal/FormModal';
import getResetPasswordUrl from 'consts/paths/pages/reset-password';
import ForgotPassword from '_types/api/ForgotPassword';
import apiUrl from 'consts/paths/api/auth/forgotPassword';
import useApi from 'hooks/useApi';
import styles from 'components/_form/form.module.scss';
import { emailRequiredSchema } from 'components/_form/schema-presets';

const schema = yup.object().shape({
    email: emailRequiredSchema,
});

export type ValuesType = {
    email: string;
};

export interface ForgotPasswordFormModalProps {
    toggle: () => void;
    isShowing: boolean;
    onComplete: (values: ValuesType) => void;
}

const ForgotPasswordFormModal = ({ toggle, isShowing, onComplete }: ForgotPasswordFormModalProps) => {
    const api = useApi();
    const initialValues: ValuesType = { email: '' };

    const onSubmit = async ({ email }: ValuesType) => {
        const url = getResetPasswordUrl();
        await api.post<ForgotPassword>(apiUrl, { email, url });
    };

    return (
        <FormModal
            title="Forget password"
            toggle={toggle}
            isShowing={isShowing}
            validationSchema={schema}
            submitButtonText="Send reset link"
            initialValues={initialValues}
            onSubmit={onSubmit}
            onComplete={onComplete}
        >
            {({ register, formState: { errors } }) => (
                <>
                    <p className="mb-5">
                        Enter the email address associated with your account, and we will email you a link to reset your
                        password.
                    </p>

                    <Form.Group className="mb-3" controlId="email">
                        <Form.Control
                            className={styles.formInput}
                            type="email"
                            placeholder="Email"
                            {...register('email')}
                            isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid" className="ms-2">
                            {errors.email?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </>
            )}
        </FormModal>
    );
};

export default ForgotPasswordFormModal;
