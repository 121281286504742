import Profile from '_types/Profile';
import { useEffect, useState } from 'react';
import { indexBy, prop } from 'ramda';

const useProfiles = () => {
    const [{ profiles, profilesIndex }, setProfiles] = useState<{
        profiles: Profile[] | undefined;
        profilesIndex: { [id: string]: Profile } | undefined;
    }>({ profiles: undefined, profilesIndex: undefined });

    useEffect(() => {
        void (async () => {
            const profiles = (await import('./profiles.json' /* webpackPrefetch: true */)).default as Profile[];
            const profilesIndex = indexBy(prop('id'), profiles);
            setProfiles({ profiles, profilesIndex });
        })();
    }, []);

    const findProfileById = (id: string): Profile | undefined => profilesIndex && profilesIndex[id];
    const getProfileShortLabel = (id: string) => findProfileById(id)?.shortLabel;

    return {
        profiles,
        findProfileById,
        getProfileShortLabel,
    };
};

export default useProfiles;
