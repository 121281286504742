import * as yup from 'yup';
import getIsRequiredText from 'functions/validation/getIsRequiredText';
import { passwordRequiredSchema } from 'components/_form/schema-presets';

export const requestAccessSchema = yup.object().shape({
    profile: yup.string().required(getIsRequiredText('Profile')),
    name: yup.string().required(getIsRequiredText('Name')),
    email: yup.string().email('Email must be a valid email').required(getIsRequiredText('Email')),
    password: passwordRequiredSchema,
    confirmPassword: yup
        .string()
        .required(getIsRequiredText('Confirm password'))
        .oneOf([yup.ref('password')], 'Passwords must match'),
    country: yup.string().required(getIsRequiredText('Country')).min(2),
    organisation: yup.string().required(getIsRequiredText('Organisation')),
    motivation: yup.string().required(getIsRequiredText('Motivation')),
    recaptchaResponse: yup.string().required(getIsRequiredText('Recaptcha response')),
});

export const requestAccessDefaultValues = {
    profile: '',
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    country: '',
    organisation: '',
    motivation: '',
    recaptchaResponse: '',
};
