const RequestSent = () => (
    <>
        <div className="p-3">
            <p> The Item Library administrator will contact you by e-mail.</p>
            <p>Once your account has been activated, you will receive an e-mail notification.</p>
            <p>
                In case of any problems, please contact us at{' '}
                <a href='\"mailto:itemlibrary@eortc.org\"'>itemlibrary@eortc.org</a>.
            </p>
        </div>
    </>
);

export default RequestSent;
