import MessageBox from 'components/_modal/MessageBox';
import useModal from 'hooks/useModal';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export type ResetPasswordSuccessfulModalProps = Record<string, never>;

const ResetPasswordSuccessfulModal = () => {
    const [isShowing, toggle] = useModal();
    const router = useRouter();

    const onMessageBoxClose = () => {
        void router.replace('/', undefined, { shallow: true });
    };

    useEffect(() => {
        if (router?.query?.resetSuccessful) {
            toggle();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <MessageBox title="Password reset!" isShowing={isShowing} onComplete={onMessageBoxClose} toggle={toggle}>
            <p className="px-3 py-1 m-0">You can now use your new password to login.</p>
        </MessageBox>
    );
};

export default ResetPasswordSuccessfulModal;
