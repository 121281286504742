import { useCallback, useState } from 'react';
import MessageBox from 'components/_modal/MessageBox';
import ForgotPasswordFormModal, { ValuesType } from './FormModal';
import ForgotPasswordEmailSent from './EmailSentModal';

export type ForgotPasswordProps = {
    toggle: () => void;
    isShowing: boolean;
};

const ForgotPassword = ({ toggle, isShowing }: ForgotPasswordProps) => {
    const [email, setEmail] = useState('');

    const onComplete = useCallback(({ email: _email }: ValuesType) => {
        setEmail(_email);
    }, []);

    return (
        <>
            {email ? (
                <MessageBox title="Email sent!" onComplete={onComplete} isShowing={isShowing} toggle={toggle}>
                    <ForgotPasswordEmailSent email={email} />
                </MessageBox>
            ) : (
                <ForgotPasswordFormModal toggle={toggle} isShowing={isShowing} onComplete={onComplete} />
            )}
        </>
    );
};

export default ForgotPassword;
