/* eslint-disable react/jsx-props-no-spreading */
import Warning from 'elements/Warning';
import { Form } from 'react-bootstrap';
import useModal from 'hooks/useModal';
import ButtonWithLoader from 'elements/ButtonWithLoader';
import styles from 'components/_form/form.module.scss';
import * as yup from 'yup';
import useForm from 'hooks/useForm';
import { emailRequiredSchema, passwordRequiredSchema } from 'components/_form/schema-presets';
import RequestAccess from '../RequestAccess';
import ForgotPassword from '../ForgotPassword';
import useUserSession from '../../../hooks/useUserSession';
import { useEffect } from 'react';

export type ValuesType = {
    email: string;
    password: string;
};

const schema = yup.object().shape({
    email: emailRequiredSchema,
    password: passwordRequiredSchema,
});

export type LoginProps = {
    email?: string;
    password?: string;
};
const Login = (props: LoginProps) => {
    const [isRequestAccessShowing, toggleRequestAccessModal] = useModal();
    const [isForgotPasswordShowing, toggleForgotPasswordModal] = useModal();

    const { logOn } = useUserSession();

    const onSubmit = async ({ email, password }: ValuesType) => {
        await logOn(email, password);
    };

    const {
        register,
        isLoading,
        warningMessage,
        formState: { errors },
        handleSubmit,
    } = useForm<ValuesType>({ schema, onSubmit, defaultValues: props });

    useEffect(() => {
        const keyDownHandler = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleSubmit();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [handleSubmit]);

    return (
        <div className={styles.formWrapper}>
            <Warning show={!!warningMessage} text={warningMessage} />
            <Form noValidate className={`${styles.form}`} onSubmit={handleSubmit}>
                <h3 className={styles.formTitle}>Log In</h3>
                <Form.Group className="mb-4" controlId="loginEmail">
                    <Form.Label className={styles.formFieldLabel}>Email</Form.Label>
                    <Form.Control
                        className={styles.formInput}
                        type="email"
                        placeholder=""
                        isInvalid={!!errors.email}
                        {...register('email')}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4" controlId="loginPassword">
                    <Form.Label className={styles.formFieldLabel}>Password</Form.Label>
                    <Form.Control
                        className={styles.formInput}
                        type="password"
                        placeholder=""
                        isInvalid={!!errors.password}
                        {...register('password')}
                    />
                    <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                </Form.Group>
                <ButtonWithLoader onClick={handleSubmit} isLoading={isLoading}>
                    Log in
                </ButtonWithLoader>
                <button type="button" className={`${styles.formLinks} mt-3`} onClick={toggleForgotPasswordModal}>
                    Forgot your password?
                </button>
                <button type="button" className={`${styles.formLinks} mb-2`} onClick={toggleRequestAccessModal}>
                    No account? Request access
                </button>
                <ForgotPassword isShowing={isForgotPasswordShowing} toggle={toggleForgotPasswordModal} />
                <RequestAccess isShowing={isRequestAccessShowing} toggle={toggleRequestAccessModal} />
            </Form>
        </div>
    );
};
export default Login;
