import { useController, useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import useProfiles from 'hooks/useProfiles';
import styles from './profileselect.module.scss';
import FormSelect, { FormSelectPropsWithoutItems } from '..';

type FormProfileSelectProps<TValues> = FormSelectPropsWithoutItems<TValues> & {
    isForAdminPanel?: boolean;
};

function FormProfileSelect<TValues>(props: FormProfileSelectProps<TValues>) {
    const { field } = useController(props);
    const { control, isForAdminPanel } = props;
    const { profiles, findProfileById } = useProfiles();
    const [selectedProfileDescription, setSelectedProfileDescription] = useState<string>();
    const profileId = useWatch({ control, name: field.name });

    useEffect(() => {
        const selectedProfile = findProfileById(profileId as unknown as string);
        const selectedProfileDescription = isForAdminPanel
            ? selectedProfile?.adminDescription || ''
            : selectedProfile?.description || '';

        setSelectedProfileDescription(selectedProfileDescription);
    }, [isForAdminPanel, profileId, findProfileById]);

    const getItems = () => {
        if (!profiles) return;

        let profilesToRender = profiles;
        if (!isForAdminPanel) {
            profilesToRender = profilesToRender.filter((item) => item.description !== '');
        }
        return profilesToRender.map(({ id, label }) => ({ value: id, label }));
    };

    return (
        <>
            <FormSelect {...props} items={getItems()} />
            <p className={selectedProfileDescription ? `${styles.profileDescription} mt-3` : 'd-none'}>
                {selectedProfileDescription}
            </p>
        </>
    );
}

export default FormProfileSelect;
