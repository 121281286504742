import { Container, Col, Row, Button } from 'react-bootstrap';
import useModal from 'hooks/useModal';
import RequestAccess from './RequestAccess';
import ResetPasswordSuccessfulModal from './ResetPasswordSuccessfulModal';
import styles from './login.module.scss';
import Login from './LoginForm';
import LoggedOutLayout from '../../layout/Layout/LoggedOutLayout';

export type LoginPageProps = Record<string, never>;

const LoginPage = () => {
    const [isShowing, toggle] = useModal();

    return (
        <Container className={styles.loginWrapper}>
            <Row>
                <Col sm="6" className="px-4">
                    <h2 className={styles.title}>EORTC Quality of Life Group Item Library</h2>
                    <p className={styles.lead}>
                        The Item Library is a database of items used in fully and partially validated EORTC quality of
                        life questionnaires.
                    </p>
                    <p>
                        This tool has been created by the EORTC Quality of Life Group with the primary aim of being used
                        during the development of new instruments.
                    </p>
                    <p>
                        Developers can access it to see how items were formulated in previous questionnaires and to
                        reuse existing validated items and their translations.
                    </p>
                    <p>
                        Access to the Item Library can also be granted to academic and commercial users as a reference
                        tool.
                    </p>
                    <br />
                    <Button variant="outline-primary" className={styles.button} onClick={toggle}>
                        Request access
                    </Button>
                </Col>
                <Col sm="6" className="px-4">
                    <Login />
                </Col>
            </Row>
            <RequestAccess isShowing={isShowing} toggle={toggle} />
            <ResetPasswordSuccessfulModal />
        </Container>
    );
};
LoginPage.layout = LoggedOutLayout;
export default LoginPage;
